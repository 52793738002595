<template>
  <div id="features" class="my-6">
    <v-container>
      <v-row class="px-4 py-12 elevation-24">
        <v-col
          v-for="(item, i) in items"
          :key="i"
          class="feature d-flex px-5"
          cols="12"
          md="4"
        >
          <div
            class="img flex-shrink-0 mr-5"
            :style="{ backgroundImage: 'url(' + item.img + ')' }"
            alt
          />
          <div class="text">
            <h2 class="mb-3">{{ item.title }}</h2>
            <p class="mb-0">{{ item.desc }}</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: "Yüksek Kalitede Profesyonel Eğitimler",
          desc:
            "Bir konaklama işletmesine yönelik ihtiyacınız olan tüm eğitimler tek bir çatı altında.",
          img: require("../../assets/img/icon-quality.svg")
        },
        {
          title: "Yer ve Zaman Bağımsız Çalışma Olanağı",
          desc:
            "e-Öğrenmenin gücüyle çalışanlarınız için isteğe bağlı öğrenmeyi etkinleştirin.",
          img: require("../../assets/img/icon-edu.svg")
        },
        {
          title: "Öğrenme Katılımını İzleyin ve Ölçün",
          desc:
            "Öğrenme analitikleriyle, insan kaynaklarınızı veriye dayalı bir biçimde yönetin.",
          img: require("../../assets/img/icon-speedometer.svg")
        }
      ]
    };
  }
};
</script>

<style lang="scss">
#features {
  .container > .row {
    background: linear-gradient(124.43deg, $color1 10.28%, $color2 89.18%);
    border-radius: 15px;
    color: #fff;
  }

  .feature {
    .img {
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }
  }
}
</style>
