<template>
  <div id="ourClients" class="pt-12 mt-12">
    <v-container>
      <v-row>
        <v-col cols="12" lg="8" class="text-center mx-auto">
          <h1 class="display-1 mb-4 font-weight-bold">
            Bizi Tercih Edenler
          </h1>
          <p>
            Sizlere sunmaya çalıştığımız öğrenme deneyiminin ve hizmet
            kalitesinin gerçek hayattaki yansımalarını müşterilerimizden
            dinleyebilirsiniz.
          </p>
        </v-col>
      </v-row>
      <v-row class="pa-4 my-4">
        <v-col class="d-flex align-center justify-center flex-wrap" cols="12">
          <img
            v-for="(client, i) in clients"
            :key="i"
            :src="client"
            alt
            class="ma-6"
            style="max-width: 300px; max-height: 110px;"
          />
        </v-col>
      </v-row>
      <v-divider class="col-md-6 mx-auto my-12" />
      <v-row>
        <v-col
          v-for="(quote, i) in quotes"
          :key="i"
          cols="12"
          md="4"
          class="mb-4"
        >
          <div class="item">
            <img
              src="../../assets/img/icon-quote.svg"
              alt
              height="24"
              class="mb-3"
            />
            <p
              class="font-italic font-weight-light text--secondary"
              style="min-height: 120px; font-size: 18px;"
            >
              {{ quote.text }}
            </p>
            <div class="d-flex align-center justify-start">
              <v-avatar color="grey lighten-2 white--text" class="mr-4">{{
                quote.avatar
              }}</v-avatar>
              <div>
                <h4>{{ quote.name }}</h4>
                <p class="text-small mb-0">{{ quote.title }}</p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      quotes: [
        {
          text:
            "Mesleki bilgimi arttırmak için eğitimlere katıldım. İnteraktif e-dersler çok etkili. Videolar, etkileşimler öğrenmeyi kolaylaştırıyor. Emeği geçenlerin eline sağlık.",
          name: "Ayşe Hatun",
          avatar: "AH",
          title: "Halkla İlişkiler Müdürü"
        },
        {
          text:
            "Turizm sektöründe yeniyim. Hem bilgimi tazelemek hem de daha iyi iş olanakları yakalamak için UTEx’i tercih ettim. Bana çok şey kattığını düşünüyorum.",
          name: "Cihan Civelek",
          avatar: "CC",
          title: "Resepsiyonist"
        },
        {
          text:
            "Öğrenmenin yaşı yok. Bunu öğrendim. Artık yaptığım işin hem benim hem de işletmem için önemini daha iyi anlayabiliyorum. Kariyer hedeflerimizi gözden geçireceğim.",
          name: "Fatma Telli",
          avatar: "FT",
          title: "Kat Hizmetleri Personeli"
        }
      ],
      clients: [
        require("../../assets/img/client-salamis-bay-conti.png"),
        require("../../assets/img/client-bel-conti.png"),
        require("../../assets/img/client-blue-dreams.png"),
        require("../../assets/img/client-seya-beach.png"),
        require("../../assets/img/client-haydarpasha-palace.png"),
        require("../../assets/img/client-noxinn.png")
      ]
    };
  }
};
</script>
