<template>
  <div id="requestDemo">
    <v-container>
      <v-row justify="center" align="center">
        <v-col sm="6">
          <v-img
            src="../../assets/img/e-learning.png"
            max-height="600"
            contain
          />
        </v-col>
        <v-col sm="6" xl="4">
          <h1 class="display-1 mb-4 font-weight-bold">
            Öğrenme Sonuçlarına <br />
            Daha Hızlı Ulaşın
          </h1>
          <p>
            Her kişinin öğrenme yolculuğu farklıdır. e-Öğrenme ile
            çalışanlarınızın kendi öğrenme yollarını keşfedebilmelerine olanak
            tanıyın. Bunun için gerekli tüm altyapıyı biz size sunabiliriz.
          </p>
          <v-btn
            rounded
            dark
            large
            color="primary"
            elevation="20"
            @click.prevent="showChat()"
            >Demo Talep Et</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  methods: {
    showChat() {
      /* eslint-disable */
      Tawk_API.toggle();
      /* eslint-enable */
    }
  }
};
</script>

<style lang="scss">
#requestDemo {
  padding-top: 60px;

  .img {
    width: 100%;
  }

  @media #{map-get($display-breakpoints, "md-and-up")} {
    padding-top: 240px;
    padding-bottom: 240px;

    .img {
      width: unset;
      height: 500px;
    }
  }
}
</style>
