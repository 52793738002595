<template>
  <div id="faq" class="pt-12 pt-lg-0 mt-12 mt-lg-0 b-0 pb-md-12">
    <v-container>
      <v-row>
        <v-col cols="12" lg="8" class="text-center mx-auto">
          <h1 class="display-1 mb-4 font-weight-bold">Sıkça Sorulan Sorular</h1>
          <p>
            UTEx ile ilgili olası sorularınızı ve yanıtlarını sizin için
            derledik. Sorularınıza burada yanıt bulamazsanız bizimle iletişime
            geçmeyi ihmal etmeyin.
          </p>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="6">
          <v-expansion-panels>
            <v-expansion-panel v-for="(item, i) in items" :key="i">
              <v-expansion-panel-header>
                <h3 class="font-weight-medium">{{ item.question }}</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>{{
                item.answer
              }}</v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12" sm="6">
          <v-img src="../../assets/img/faq-img.png" contain max-height="600" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          question: "Eğitimler yüz yüze mi?",
          answer:
            "Eğitimlerdeki konu anlatımları interaktif e-ders formatındadır. Video, ses, animasyon ve etkileşim öğeleriyle zenginleştirilerek geliştirilmiş interaktif e-dersler ile dilediğiniz zaman dilediğiniz yerden öğrenebilirsiniz."
        },
        {
          question: "Eğitimler sonunda sertifika veriliyor mu?",
          answer:
            "Evet, başarılı olunan her eğitimin sonunda UTEx'in ulusal ve uluslarası organizasyonlar tarafından tanınan katılım sertifikası verilmektedir."
        },
        {
          question: "Çalışanlarımızın öğrenme sürecini takip edebilir miyiz?",
          answer:
            "Evet. UTEx'in gelişmiş öğrenme analitikleriyle çalışanlarınızın öğrenme performansını anlık olarak takip edebilirsiniz."
        },
        {
          question: "Mobil cihazlardan ders çalışılabilir mi?",
          answer:
            "Evet. UTEx'in e-öğrenme altyapısı cihaz ve platform bağımsız olarak tasarlandığından her türlü mobil cihazdan ve işletim sisteminden UTEx'in interaktif e-derslerinize çalışabilirsiniz."
        }
      ]
    };
  }
};
</script>

<style lang="scss">
#faq {
  .v-expansion-panel {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 30px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px;
    background-color: #f1f1f1;

    &::before {
      box-shadow: none;
      border: none 0 transparent;
    }

    &::after {
      box-shadow: none;
      border: none 0 transparent;
    }
  }

  .img {
    width: 100%;

    @media #{map-get($display-breakpoints, "md-and-up")} {
      width: unset;
      height: 500px;
    }
  }
}
</style>
