














import Vue from "vue";
import Banner from "./Banner.vue";
import Features from "./Features.vue";
import OurClients from "./OurClients.vue";
import RequestDemo from "./RequestDemo.vue";
import Faq from "./Faq.vue";

export default Vue.extend({
  // eslint-disable-next-line
  metaInfo: {
    title: "Ana Sayfa"
  },
  components: {
    Banner,
    Features,
    OurClients,
    RequestDemo,
    Faq
  }
});
